/* In src/App.css */
nav ul {
    list-style-type: none;
    padding: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 100;
}

    nav ul li {
        display: inline;
        margin-right: 20px;
    }

        nav ul li a {
            text-decoration: none;
            color: black; /* Or any color you prefer */
        }

.home {
    height: 100vh;
    background-image: url('./img/big_background.jpg'); /* Update with your image path */
    background-size: cover;
    background-position: center;
    color: white; /* Assuming you want white text for contrast */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

    .home::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
        z-index: 1;
        pointer-events: none; /* Allows clicks to pass through */
    }

    .home > * {
        position: relative;
        z-index: 2;
    }